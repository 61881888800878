<template>
  <div></div>
</template>

<script>
export default {
  props: ['url'],

  created() {
    // 保存当前页面滚动位置
    this.$store.commit('saveScrollPosition', localStorage.getItem("scrollY"));

    // 获取外部链接地址
    const externalLink = this.url;
    // 执行跳转
    window.location.href = externalLink;
  }
}
</script>
